import {
  LAYOUT_NAME_CARD_SMALL,
  LAYOUT_NAME_CARD_MEDIUM,
  LAYOUT_NAME_CARD_LARGE,
  LAYOUT_NAME_SIDE_BY_SIDE,
  LAYOUT_NAME_GRID,
  LAYOUT_NAME_TEXT_ON_IMAGE_SMALL,
  LAYOUT_NAME_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_NAME_PG_TEXT_ON_IMAGE_SMALL,
  LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_NAME_PG_TEXT_ON_IMAGE_LARGE,
  LAYOUT_NAME_PG_CARD_MEDIUM,
  LAYOUT_NAME_PG_SIDE_BY_SIDE,
  LAYOUT_NAME_PG_GRID,
  LAYOUT_NAME_SIDE_BY_SIDE_MOBILE,
} from '@wix/communities-blog-client-common';
// import {
//   LAYOUT_NAME_MINIMAL,
//   LAYOUT_NAME_EDITORIAL,
//   LAYOUT_NAME_POSTCARD,
//   LAYOUT_NAME_MAGAZINE,
//   LAYOUT_NAME_TILED,
//   LAYOUT_NAME_BRICK,
//   LAYOUT_NAME_ONE_COLUMN,
// } from '../../custom-feed-widget/constants/layouts';
// HACK
const LAYOUT_NAME_MINIMAL = 'minimal';
const LAYOUT_NAME_EDITORIAL = 'editorial';
const LAYOUT_NAME_POSTCARD = 'postcard';
const LAYOUT_NAME_MAGAZINE = 'magazine';
const LAYOUT_NAME_TILED = 'tiled';
const LAYOUT_NAME_BRICK = 'brick';
const LAYOUT_NAME_ONE_COLUMN = 'one-column';

const MAX_HEIGHT = 1000;
const RECENT_POSTS = 'recent-posts';

const ITEM_COVER_CONFIG = {
  [LAYOUT_NAME_CARD_SMALL]: {
    minHeight: 60,
    maxHeight: 450,
    fixedWidth: 292,
  },
  [LAYOUT_NAME_CARD_MEDIUM]: {
    minHeight: 90,
    maxHeight: 700,
    fixedWidth: 454,
  },
  [LAYOUT_NAME_CARD_LARGE]: {
    fixedHeight: 400,
    fixedWidth: 940,
  },
  [LAYOUT_NAME_SIDE_BY_SIDE]: {
    fixedHeight: 362,
    fixedHeightAlternate: 306,
    fixedWidth: 469,
  },
  [LAYOUT_NAME_GRID]: {
    fixedHeight: 165,
    fixedWidth: 290,
  },
  [RECENT_POSTS]: {
    fixedHeight: 165,
    fixedWidth: 290,
  },
  [LAYOUT_NAME_TEXT_ON_IMAGE_SMALL]: {
    fixedHeight: 292,
    fixedWidth: 292,
  },
  [LAYOUT_NAME_TEXT_ON_IMAGE_MEDIUM]: {
    fixedHeight: 454,
    fixedWidth: 454,
  },
  [LAYOUT_NAME_MINIMAL]: {
    fixedHeight: 165,
    fixedWidth: 290,
  },
  [LAYOUT_NAME_EDITORIAL]: {
    fixedHeight: 165,
    fixedWidth: 290,
  },
  [LAYOUT_NAME_POSTCARD]: {
    fixedHeight: 292,
    fixedWidth: 292,
  },
  [LAYOUT_NAME_MAGAZINE]: {
    fixedHeight: 454,
    fixedWidth: 454,
  },
  [LAYOUT_NAME_TILED]: {
    minHeight: 60,
    maxHeight: 450,
    fixedWidth: 292,
  },
  [LAYOUT_NAME_BRICK]: {
    minHeight: 90,
    maxHeight: 700,
    fixedWidth: 454,
  },
  [LAYOUT_NAME_ONE_COLUMN]: {
    fixedHeight: 400,
    fixedWidth: 940,
  },
  [LAYOUT_NAME_PG_CARD_MEDIUM]: {
    minHeight: 90,
    maxHeight: 700,
    fixedWidth: 454,
  },
  [LAYOUT_NAME_PG_GRID]: {
    fixedHeight: 165,
    fixedWidth: 290,
  },
  [LAYOUT_NAME_PG_SIDE_BY_SIDE]: {
    fixedHeight: 362,
    fixedHeightAlternate: 306,
    fixedWidth: 469,
  },
  [LAYOUT_NAME_PG_TEXT_ON_IMAGE_SMALL]: {
    fixedHeight: 300,
    fixedWidth: 300,
  },
  [LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM]: {
    fixedHeight: 454,
    fixedWidth: 454,
  },
  [LAYOUT_NAME_PG_TEXT_ON_IMAGE_LARGE]: {
    fixedHeight: 1000,
    fixedWidth: 1000,
  },
  [LAYOUT_NAME_SIDE_BY_SIDE_MOBILE]: {
    fixedHeight: 70,
    fixedWidth: 70,
  },
};

export const calculateHeight = ({
  imageWidth,
  imageHeight,
  fixedHeightAlternate,
  fixedWidth,
  minHeight,
  maxHeight,
  fixedHeight,
  useAlternateDimesions,
}) => {
  if (useAlternateDimesions && fixedHeightAlternate) {
    return fixedHeightAlternate;
  }

  if (fixedHeight) {
    return fixedHeight;
  }

  const calculated = Math.ceil((fixedWidth * imageHeight) / imageWidth);
  return Math.max(
    minHeight || 0,
    Math.min(maxHeight || MAX_HEIGHT, calculated),
  );
};

export const getImageContainerSize = (
  image,
  type,
  useAlternateDimesions = false,
) => {
  const config = ITEM_COVER_CONFIG[type];
  if (!image || !config) {
    return { width: undefined, height: undefined };
  }

  return {
    width: config.fixedWidth,
    height: calculateHeight({
      imageWidth: image.width,
      imageHeight: image.height,
      ...config,
      useAlternateDimesions,
    }),
  };
};
