import React from 'react';
import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import DefaultDesktopLayout from '../../../common/components/default-desktop-layout';
import Page from '../../../common/components/page';
import { connect } from '../../../common/components/runtime-context';
import { getIsSearchLoading } from '../../../common/store/is-loading/is-loading-selectors';
import NoSearchResults from '../../components/no-search-results';
import SearchResultsList from '../../components/search-results-list';
import { getSearchResult } from '../../selectors/search-selectors';
import { decodeQuery } from '../../services/query-encoding';
import SearchPage from '../search-page';

const ResultsPage = ({ result, isLoading, query }) => {
  const hasResults = Boolean(get(result, 'length', 0));

  const renderResults = () => {
    return hasResults ? (
      <SearchResultsList posts={result} query={query} />
    ) : (
      <NoSearchResults />
    );
  };

  return (
    <Page noSpacing={!hasResults}>
      <DefaultDesktopLayout>
        <AnimatedLoader isLoading={isLoading}>
          {query ? renderResults() : <SearchPage />}
        </AnimatedLoader>
        {!isLoading && <AppLoaded />}
      </DefaultDesktopLayout>
    </Page>
  );
};

ResultsPage.propTypes = {
  result: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  query: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps) => ({
  result: getSearchResult(state),
  isLoading: getIsSearchLoading(state),
  query: decodeQuery(get(ownProps, 'params.query')),
});

export default flowRight(connect(mapRuntimeToProps))(ResultsPage);
